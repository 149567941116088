:root {
  --primary-color: #4a4e69;
  --secondary-color: #9a8c98;
  --tertiary-color: #c9ada7;
  --quaternary-color: #f2e9e4;
}

.header {
  display: inline-block;
  width: 100%;
  padding: 20px;
  text-align: center;
  background-color: var(--primary-color);
  color: var(--quaternary-color);
  position: fixed;
  top: 0;
  left: 0;
}

body {
  font-family: "Roboto", sans-serif;
  background-color: var(--quaternary-color);
  color: var(--primary-color);
  padding-top: 60px;
  margin-bottom: 120px;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
  margin-top: 100px;
  padding-bottom: 60px;
}

button {
  padding: 15px;
  border: none;
  border-radius: 5px;
  background-color: var(--primary-color);
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: var(--secondary-color);
}

h1 {
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 20px;
}

input {
  width: 80%;
  padding: 15px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  font-size: 16px;
  color: var(--primary-color);
}

.Loading,
.Error {
  color: var(--secondary-color);
  font-size: 18px;
  text-align: center;
  margin-top: 20px;
}

.CountryInfo {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 10px;
  background-color: var(--tertiary-color);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.footer {
  width: 100%;
  padding: 20px;
  text-align: center;
  position: absolute;
  bottom: -290px;
  background-color: var(--primary-color);
  color: var(--quaternary-color);
}

.social-icons {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icons li {
  margin: 0 10px;
}

.social-icons a {
  display: block;
  width: 32px;
  height: 32px;
  padding: 4px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.social-icons a:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.social-icons img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
